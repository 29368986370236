import httpReauset from '@/utils/httpReauset'

// 搜索
export function getSearchData(data) {
    return httpReauset({
        url: '/index/search',
        method: 'post',
        data: data,

    })
}

// 导航栏
export function getTitleText(data) {
    return httpReauset({
        url: '/index/titles',
        method: 'post',
        data: data,

    })
}

// 首页 轮播图
export function getHomeBanner(data) {
    return httpReauset({
        url: '/index/banner',
        method: 'post',
        data: data,
    })
}

// 首页 关于太阳成tyc9728
export function getHomeAbout(data) {
    return httpReauset({
        url: '/index/about',
        method: 'post',
        data: data,
    })
}

// 首页 科技创新
export function getHomeTechnology(data) {
    return httpReauset({
        url: '/index/science',
        method: 'post',
        data: data,
    })
}

// 首页 产业布局
export function getHomeLayout(data) {
    return httpReauset({
        url: '/Entering/industry',
        method: 'post',
        data: data,
    })
}
// 首页 集团荣誉
export function getHomeHonor(data) {
    return httpReauset({
        url: '/index/honor',
        method: 'post',
        data: data,
    })
}

// 走进太阳成tyc9728
export function getAboutLayout(data) {
    return httpReauset({
        url: '/Entering/culture',
        method: 'post',
        data: data,
    })
}

// 产业布局
export function getLayoutIndustry(data) {
    return httpReauset({
        url: '/index/industry',
        method: 'post',
        data: data,
    })
}

// 提交留言
export function getFromData(data) {
    return httpReauset({
        url: '/Entering/message',
        method: 'post',
        data: data,

    })
}
// 产业布局详情
export function getLayoutDetails(data) {
    return httpReauset({
        url: '/Entering/industry_find',
        method: 'post',
        params: data,
    })
}

// 科技创新
export function getTechnologyIn(data) {
    return httpReauset({
        url: '/index/science',
        method: 'post',
        data: data,
    })
}
// 新闻中心
export function getNewsView(data) {
    return httpReauset({
        url: '/index/news',
        method: 'get',
        params: data,
    })
}
// 新闻中心
export function getNewsPagelist(data) {
    return httpReauset({
        url: '/Entering/news',
        method: 'get',
        params: data,
    })
}
// 新闻中心详情
export function getNewDetails(data) {
    return httpReauset({
        url: '/index/new_find',
        method: 'post',
        params: data,
    })
}
// 全媒体矩阵 PC端
export function getNewMatrix(data) {
    return httpReauset({
        url: '/Entering/matrix',
        method: 'post',
        data: data,
    })
}
// 全媒体矩阵 移动端
export function getNewMatrixPhone(data) {
    return httpReauset({
        url: '/Entering/matrixs',
        method: 'post',
        data: data,
    })
}
// 可持续发展
export function getDevelopmentView(data) {
    return httpReauset({
        url: '/Entering/welfare',
        method: 'post',
        data: data,
    })
}
// 职业发展
export function getCareerDevelopment(data) {
    return httpReauset({
        url: '/entering/career',
        method: 'post',
        data: data,

    })
}

// 联系我们
export function getContactus(data) {
    return httpReauset({
        url: '/Entering/contact',
        method: 'post',
        data: data,
    })
}

// 底部
export function getFootLink(data) {
    return httpReauset({
        url: '/index/bottom',
        method: 'post',
        data: data,
    })
}

// 底部 集团站群
export function getGroupStation(data) {
    return httpReauset({
        url: '/Entering/marketings',
        method: 'post',
        data: data,
    })
}