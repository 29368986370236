import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入element语言包
const cn = {
  header: {
    index: '首页',
    zoujin: '走进太阳成tyc9728',
    zoujinerji: {
      jianjie: '集团简介',
      wenhua: '企业文化',
      licheng: '发展历程',
      rongyu: '集团荣誉'
    },
    chanye: '产业布局',
    chanyerji: {
      shengwu: '生物纤维产业',
      tezhong: '特种纸品产业',
      weisheng: '卫生材料产业',
      qita: '其他产业'
    },
    zhuoyue: '卓越科技',
    zhuoyuerji: {
      jishu: '技术中心',
      chanxue: '产学研合作',
      zhuanli: '专利及科技成果'
    },
    xinwen: '新闻中心',
    xinwenerji: {
      jituan: '集团新闻',
      hangye: '行业资讯',
      quanmeiti: '全媒体矩阵'
    },
    zeren: '可持续发展',
    zerenerji: {
      huanjing: '循环经济',
      gongyi: '社会公益'
    },
    rencai: '人才强企',
    rencaierji: {
      zhanlue: '人才战略',
      zhaopin: '招聘信息',
      huodong: '员工活动'
    },
    lianxi: '联系我们'
  },
  placeholder: '请输入关键词',
  search: '搜索',
  look: '了解更多',
  aboutHeng: '关于太阳成tyc9728',
  indexLayout: '产业布局',
  indexTechnology: '卓越科技',
  indexHonor: '集团荣誉',
  indexNew: '新闻中心',
  indexLearn: '了解更多资讯',
  topTen: '十大理念',
  technicalCenter: '技术中心',
  technologyInfor: '科技创新资讯',
  majorInnova: '重大创新',
  nationalPat: '国家专利',
  inventionPat: '发明专利',
  drafting: '起草标准',
  scienceTec: '科技进步奖',
  groupNews: '集团新闻',
  industryInfor: '行业资讯',
  all: '全部',
  mediaTitle: '全媒体矩阵',
  welfareTitle: '社会公益',
  environment: '循环经济',
  publicWelfare: '社会公益',
  employeActive: '员工活动',
  marketDroup: '营销站群',
  address: '地址',
  phone: '电话',
  email: '邮箱',
  code: '邮编',
  name: '姓名',
  relationGroup: '地区',
  textMessage: '需求说明',
  submitNow: '立即提交',
  submitSuccess: '提交成功',
  coop: '我们将尽快与您联系，感谢您对太阳成集团tyc9728的关注！',
  know: '知道了',
  submitFail: '提交失败',
  submitPublic: '可能是您所处的网络环境问题或其他问题，请重新提交～',
  back: '返回重试',
  relationMessage: '留言',
  messageText: '如需要太阳成集团tyc9728提供进一步服务，请您认真填写表格信息；提交成功后，我们的专业服务人员会尽快与您取得联系。<br/>我们会对通过本网站收集的个人信息保密。',
  pleaseName: '请输入姓名',
  pleasePhone: '请输入电话',
  pleaseMobile: '请输入正确的手机号码',
  pleaseEmail: '请输入邮箱',
  pleaseTrue: '请输入正确的邮箱',
  comapanyIndu: '公司简介',
  leadProducts: '主导产品',
  relationPop: '我们始终在您身边。如需任何帮助或咨询，请随时联系我们，我们将竭诚为您服务！',
  year: '年',
  swiperSee: '左右滑动查看更多',
  million: '亿',
  totalAsset: '资产总值',
  numberEmploy: '员工数量',
  professionalTec: '专业技术人才',
  deeply: '行业深耕',
  mediaLLook: '点击您想了解的公司即可查看媒体',
  noFurther: '暂无更多数据',
  previousUp: '上一篇',
  previousDown: '下一篇',
  detailCn: '详情',
  backList: '返回列表',
  viewVolume: '浏览量',
  groupStation: '集团站群',
  viewJob: '查看详细岗位信息',
  layoutContact: '联系我们',
  
  ...zhLocale //这里引入element语言包目的是在切换语言的时候，element的组件里面的文字能跟随切换
}
// 导出
export default cn;
